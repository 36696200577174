<template lang="pug">
EcreativAtomsHeading(tag="h2" size="3") Latest Articles
.articlesGrid.d-grid.mb-5.row-gap-4.column-gap-4(v-if="articles.length")
  .item(v-for='item in articles', :key='item.id')
    EcreativMoleculesCardsArticleCard(
      :id='item.id',
      :key='item.id',
      :image='item.image',
      :title='item.title',
      :content="''",
      :truncateContent="''",
      :link='item.slug',
      linkLabel="Read More",
      category='articles',
      :date='item.date'
    )
div(v-else)
  p There are no articles yet.
EcreativMoleculesButtonsLoadMore.mb-5(
  v-if="!articlesStore.hideLoadMore",
  :isLoading="articlesStore.isLoading"
)
</template>

<script setup>
import { defineProps } from 'vue';
import EcreativAtomsHeading from '../atoms/Heading.vue';
import EcreativMoleculesButtonsLoadMore from '../molecules/Buttons/LoadMore.vue';
import EcreativMoleculesCardsArticleCard from '../molecules/Cards/ArticleCard.vue';
const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.articlesGrid {
  grid-template-columns: auto;

  @include media-breakpoint-up(sm) {
    grid-template-columns: auto auto;
    column-gap: 25px;
  }
}
</style>
