<template lang="pug">
EcreativOrganismsPresentingLatestArticlesAndCallToLoadMore(:articles="generatedData")
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EcreativOrganismsPresentingLatestArticlesAndCallToLoadMore from '../organisms/PresentingLatestArticlesAndCallToLoadMore.vue';

const props = defineProps({
  articleCount: {
    type: Number,
    default: 1,
  },
});

const articleSchema = {
  title: 'Title Lorem ipsum dolor sit amet.',
  image: 'https://source.unsplash.com/random/275x430/?fruit,yellow',
  link: '#',
  content: '',
  truncateContent:
    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid officiis hic aliquam distinctio est possimus. Sequi, repudiandae numquam. Harum quaerat adipisci, accusantium nostrum ex minima dolore omnis ipsa eaque ab.',
  index: 1,
};

const generatedData = computed(() => {
  return Array.from({ length: props.articleCount }, (_, index) => ({
    ...articleSchema,
    index,
  }));
});
</script>
